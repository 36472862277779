import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import Fuse, { IFuseOptions } from 'fuse.js';

import { DropdownOption } from 'components/Dropdown';

import { courseStatusOptions } from 'constants/courseStatusOptions';

import { StudentOptionFragment } from 'graphql/types/generated';

import {
  CoursesPageType,
  CoursesPayload,
  CoursesState,
  CoursesView,
  FilterableCourse,
  FiltersState,
  RootState,
} from './types';

/**
 * Reducers
 */
const initialState: CoursesState = {
  teacher: {
    view: 'grid',
    courses: [],
    filters: {
      currentStatus: courseStatusOptions[0],
      currentEnrolledStudents: [],
      currentGradeYears: [],
      currentSubjects: [],
    },
    searchQuery: '',
  },
  student: {
    view: 'grid',
    courses: [],
    filters: {
      currentSubjects: [],
    },
    searchQuery: '',
  },
  organization: {
    view: 'list',
    courses: [],
    filters: {
      currentGradeYears: [],
      currentSubjects: [],
    },
    searchQuery: '',
  },
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCourses: (
      state: CoursesState,
      action: PayloadAction<CoursesPayload<FilterableCourse[]>>
    ) => {
      const type = action.payload.type;
      state[type].courses = action.payload.value;
    },
    resetFilters: (
      state: CoursesState,
      action: PayloadAction<CoursesPageType>
    ) => {
      state[action.payload].filters = initialState[action.payload].filters;
    },
    setCoursesView: (
      state: CoursesState,
      action: PayloadAction<CoursesPayload<CoursesView>>
    ) => {
      state[action.payload.type].view = action.payload.value;
    },
    setCoursesSearchQuery: (
      state: CoursesState,
      action: PayloadAction<CoursesPayload<string>>
    ) => {
      const type = action.payload.type;
      state[type].searchQuery = action.payload.value;
    },
    setCurrentStatus: (
      state: CoursesState,
      action: PayloadAction<CoursesPayload<DropdownOption<string>>>
    ) => {
      const type = action.payload.type;
      state[type].filters.currentStatus = action.payload.value;
    },
    setCurrentEnrolledStudents: (
      state: CoursesState,
      action: PayloadAction<
        CoursesPayload<DropdownOption<StudentOptionFragment>[]>
      >
    ) => {
      const type = action.payload.type;
      state[type].filters.currentEnrolledStudents = action.payload.value;
    },
    setCurrentSubjects: (
      state: CoursesState,
      action: PayloadAction<CoursesPayload<DropdownOption<string>[]>>
    ) => {
      const type = action.payload.type;
      state[type].filters.currentSubjects = action.payload.value;
    },
    setCurrentGradeYears: (
      state: CoursesState,
      action: PayloadAction<CoursesPayload<DropdownOption<string>[]>>
    ) => {
      const type = action.payload.type;
      state[type].filters.currentGradeYears = action.payload.value;
    },
  },
});

/**
 * Actions
 */
export const {
  setCourses,
  resetFilters,
  setCoursesView,
  setCoursesSearchQuery,
  setCurrentStatus,
  setCurrentEnrolledStudents,
  setCurrentSubjects,
  setCurrentGradeYears,
} = coursesSlice.actions;

/**
 * Selectors
 */
export const getCourses = (state: RootState): CoursesState => state.courses;

export const getAllCourses = (type: CoursesPageType) =>
  createSelector(getCourses, (courses) => courses[type].courses);

export const getCoursesView = (type: CoursesPageType) =>
  createSelector(getCourses, (courses: CoursesState) => courses[type].view);

export const getCoursesSearchQuery = (type: CoursesPageType) =>
  createSelector(getCourses, (courses) => courses[type].searchQuery);

export const getCoursesFilters = (type: CoursesPageType) =>
  createSelector(getCourses, (courses: CoursesState) => courses[type].filters);

export const getNumFiltersApplied = (type: CoursesPageType) =>
  createSelector(getCoursesFilters(type), (filters: FiltersState) => {
    const {
      currentStatus,
      currentEnrolledStudents,
      currentSubjects,
      currentGradeYears,
    } = filters;

    return (
      (!!currentStatus ? 1 : 0) +
      (currentEnrolledStudents?.length ?? 0) +
      (currentSubjects?.length ?? 0) +
      (currentGradeYears?.length ?? 0)
    );
  });

const fuseOptions: IFuseOptions<FilterableCourse> = {
  keys: [
    { name: 'name', weight: 1 },
    { name: 'subjects', weight: 1 },
    { name: 'gradeYears', weight: 1 },
    { name: 'gradeYearsLabels', weight: 1 },
    { name: 'studentNames', weight: 1 },
    { name: 'description', weight: 1 },
  ],
  threshold: 0.2,
  ignoreLocation: true,
  ignoreFieldNorm: true,
};

export const filterCourses = (
  searchQuery: string,
  courses: FilterableCourse[],
  currentStatus: DropdownOption<string> | null = null,
  currentEnrolledStudents: DropdownOption<StudentOptionFragment>[] = [],
  currentSubjects: DropdownOption<string>[] = [],
  currentGradeYears: DropdownOption<string>[] = []
) => {
  // Search Results via Search Query
  const fuse = new Fuse(courses, fuseOptions);
  const searchResults = searchQuery
    ? fuse.search(searchQuery).map((result) => result.item)
    : courses;

  // Filtered Search Results via Filters
  return searchResults.filter((course) => {
    // Status Filter
    const courseStatus = course.archived ? 'Archived' : 'Active';
    const matchesCourseStatus =
      !currentStatus || currentStatus.value === courseStatus;

    // Enrolled Students Filter
    const currentEnrolledStudentsIds = currentEnrolledStudents.map(
      (student) => student.value.id
    );
    const courseEnrolledStudentIds = course.studentCourses?.flatMap(
      (studentCourse) => studentCourse.student.id
    );
    const matchesEnrolledStudents =
      currentEnrolledStudentsIds.length === 0 ||
      currentEnrolledStudentsIds.some((studentId) =>
        courseEnrolledStudentIds.includes(studentId)
      );

    // Subjects Filter
    const currentSubjectsValues = currentSubjects.map(
      (subject) => subject.value
    );
    const subjects = (course.subjects ?? []) as string[];
    const matchesSubjects =
      currentSubjectsValues.length === 0 ||
      currentSubjectsValues.some((subject) => subjects.includes(subject));

    // Grade Years Filter
    const currentGradeYearValues = currentGradeYears.map(
      (gradeYear) => gradeYear.value
    );
    const gradeYears = (course.gradeYears ?? []) as string[];
    const matchesGradeYears =
      currentGradeYearValues.length === 0 ||
      currentGradeYearValues.some((gradeYear) =>
        gradeYears.includes(gradeYear)
      );

    return (
      matchesCourseStatus &&
      matchesEnrolledStudents &&
      matchesGradeYears &&
      matchesSubjects
    );
  });
};

export const getFilteredCourses = (type: CoursesPageType) =>
  createSelector(
    getAllCourses(type),
    getCoursesFilters(type),
    getCoursesSearchQuery(type),
    (allFilterableCourses, filters, searchQuery) => {
      const {
        currentStatus,
        currentEnrolledStudents,
        currentGradeYears,
        currentSubjects,
      } = filters;
      return filterCourses(
        searchQuery,
        allFilterableCourses,
        currentStatus,
        currentEnrolledStudents,
        currentSubjects,
        currentGradeYears
      );
    }
  );
