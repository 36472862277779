import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PageLoader from 'components/PageLoader';
import { showToast } from 'components/Toast';

import { useTheme } from 'context/Theme';

import UserRole from 'enums/UserRole';

import {
  useGetStudentAccountInfoLazyQuery,
  useGetTeacherInfoLazyQuery,
} from 'graphql/types/generated';

import { useAppDispatch } from 'redux/store';
import {
  getUserId,
  getUserRole,
  setUserOrganizationId,
  setUserOrganizationName,
  setUserPlanTier,
  setUserSettings,
  setUserSubscriptionStatus,
} from 'redux/user';

import styles from './App.module.scss';

export interface AppLoaderProps {
  children: JSX.Element;
}

const AppLoader = ({ children }: AppLoaderProps) => {
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState<boolean>(true);

  const { loading: isThemeLoading } = useTheme();

  const dispatch = useAppDispatch();

  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRole);

  const [getTeacherInfo, { data: teacherInfoData, error: teacherError }] =
    useGetTeacherInfoLazyQuery({
      onError: () => {
        setIsLoadingUserInfo(false);
      },
    });
  const [
    getStudentAccountInfo,
    { data: studentAccountData, error: studentAccountError },
  ] = useGetStudentAccountInfoLazyQuery({
    onError: () => {
      setIsLoadingUserInfo(false);
    },
  });

  useEffect(() => {
    if (userRole === UserRole.Teacher) {
      getTeacherInfo({ variables: { teacherId: userId } });
    } else if (userRole === UserRole.Student) {
      getStudentAccountInfo({ variables: { studentAccountId: userId } });
    }
  }, [userId, userRole, getTeacherInfo, getStudentAccountInfo]);

  useEffect(() => {
    if (teacherInfoData) {
      if (teacherInfoData.teacher?.customer) {
        dispatch(setUserPlanTier(teacherInfoData.teacher.customer.planTier));
        dispatch(
          setUserSubscriptionStatus(
            teacherInfoData.teacher.customer.subscriptionStatus ?? null
          )
        );
      }
      if (teacherInfoData.teacher?.userSettings) {
        dispatch(setUserSettings(teacherInfoData.teacher.userSettings));
      }
      if (teacherInfoData.teacher?.organization) {
        dispatch(
          setUserOrganizationId(teacherInfoData.teacher.organization.id)
        );
        dispatch(
          setUserOrganizationName(teacherInfoData.teacher.organization.name)
        );
      }

      setIsLoadingUserInfo(false);
    } else if (studentAccountData) {
      if (studentAccountData.studentAccount?.userSettings) {
        dispatch(
          setUserSettings(studentAccountData.studentAccount.userSettings)
        );
      }
      if (
        studentAccountData.studentAccount?.student.teachers[0]?.teacher
          .organization?.id
      ) {
        dispatch(
          setUserOrganizationId(
            studentAccountData.studentAccount?.student.teachers[0]?.teacher
              .organization?.id
          )
        );
      }
      setIsLoadingUserInfo(false);
    }
  }, [teacherInfoData, studentAccountData, dispatch]);

  useEffect(() => {
    (teacherError || studentAccountError) &&
      showToast(
        'userInfoError',
        'Error getting user info',
        'There was an issue retrieving your information. Try refreshing the page.',
        'error'
      );
  }, [teacherError, studentAccountError]);

  return isLoadingUserInfo || isThemeLoading ? (
    <PageLoader className={styles.LoadingIndicator} />
  ) : (
    children
  );
};

export default AppLoader;
