import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { differenceInCalendarDays, parseISO } from 'date-fns';

import CalculatedStudentAssignmentStatus from 'enums/CalculatedStudentAssignmentStatus';

import {
  HomeStudentAssignmentFragment,
  Student_Assignment_Status_Enum,
} from 'graphql/types/generated';

import Filters, { HomeState, RootState } from './types';

/**
 * Reducers
 */
const initialState: HomeState = {
  filters: {
    studentId: null,
    courseId: null,
    assignmentType: null,
    studentAssignmentStatus: null,
  },
  homeStudentAssignments: null,
  selectedHomeStudentAssignmentId: null,
  isDetailsSlideMenuOpen: false,
  isWelcomeToSyllabirdModalOpen: false,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomeStudentAssignments: (
      state: HomeState,
      action: PayloadAction<HomeStudentAssignmentFragment[] | null>
    ) => {
      state.homeStudentAssignments = action.payload;
    },
    selectHomeStudentAssignment: (
      state: HomeState,
      action: PayloadAction<string | null>
    ) => {
      state.selectedHomeStudentAssignmentId = action.payload;

      // only open menu if we've actually selected an assignment
      if (action.payload !== null) {
        state.isDetailsSlideMenuOpen = true;
      }
    },
    setStudentFilter: (
      state: HomeState,
      action: PayloadAction<string | null>
    ) => {
      state.filters.studentId = action.payload;
    },
    setCourseFilter: (
      state: HomeState,
      action: PayloadAction<string | null>
    ) => {
      state.filters.courseId = action.payload;
    },
    setAssignmentTypeFilter: (
      state: HomeState,
      action: PayloadAction<string | null>
    ) => {
      state.filters.assignmentType = action.payload;
    },
    setStudentAssignmentStatusFilter: (
      state: HomeState,
      action: PayloadAction<
        | Student_Assignment_Status_Enum
        | CalculatedStudentAssignmentStatus
        | null
      >
    ) => {
      state.filters.studentAssignmentStatus = action.payload;
    },
    setIsDetailsSlideMenuOpen: (
      state: HomeState,
      action: PayloadAction<boolean>
    ) => {
      state.isDetailsSlideMenuOpen = action.payload;
    },
    setIsWelcomeToSyllabirdModalOpen: (
      state: HomeState,
      action: PayloadAction<boolean>
    ) => {
      state.isWelcomeToSyllabirdModalOpen = action.payload;
    },
    clearFilters: (state: HomeState) => {
      state.filters = initialState.filters;
    },
  },
});

/**
 * Actions
 */
export const {
  setHomeStudentAssignments,
  selectHomeStudentAssignment,
  setStudentFilter,
  setCourseFilter,
  setAssignmentTypeFilter,
  setStudentAssignmentStatusFilter,
  setIsDetailsSlideMenuOpen,
  setIsWelcomeToSyllabirdModalOpen,
  clearFilters,
} = homeSlice.actions;

/**
 * Selectors
 */
const getHome = (state: RootState): HomeState => state.home;

export const getFilters = createSelector(
  getHome,
  (home: HomeState) => home.filters
);

export const getHomeStudentAssignments = createSelector(
  getHome,
  (home: HomeState) => home.homeStudentAssignments
);

export const getHomeStudentAssignmentsLength = createSelector(
  getHomeStudentAssignments,
  (studentAssignments: HomeStudentAssignmentFragment[] | null) =>
    studentAssignments?.length ?? null
);

export const getSelectedHomeStudentAssignmentId = createSelector(
  getHome,
  (home: HomeState) => home.selectedHomeStudentAssignmentId
);

export const getIsDetailsSlideMenuOpen = createSelector(
  getHome,
  (home: HomeState) => home.isDetailsSlideMenuOpen
);

export const getIsWelcomeToSyllabirdModalOpen = createSelector(
  getHome,
  (home: HomeState) => home.isWelcomeToSyllabirdModalOpen
);

export const isSelectedHomeStudentAssignment = (studentAssignmentId: string) =>
  createSelector(
    getSelectedHomeStudentAssignmentId,
    (selectedHomeStudentAssignmentId: string | null) =>
      studentAssignmentId === selectedHomeStudentAssignmentId
  );

const isHomeStudentAssignmentAssignedToStudent =
  (studentId: string | null) =>
  (studentAssignment: HomeStudentAssignmentFragment) =>
    !studentId || studentId === studentAssignment.student.id;

const isHomeStudentAssignmentForCourse =
  (courseId: string | null) =>
  (studentAssignment: HomeStudentAssignmentFragment) =>
    !courseId || courseId === studentAssignment.assignment.course.id;

const doesHomeStudentAssignmentHaveType =
  (assignmentType: string | null) =>
  (studentAssignment: HomeStudentAssignmentFragment) =>
    !assignmentType ||
    assignmentType === studentAssignment.assignment.customAssignmentType?.id;

// TODO: extract logic into function and test
const doesHomeStudentAssignmentHaveStatus =
  (
    assignmentStatus:
      | Student_Assignment_Status_Enum
      | CalculatedStudentAssignmentStatus
      | null
  ) =>
  (studentAssignment: HomeStudentAssignmentFragment) => {
    // Always filter out unscheduled student assignments
    if (!studentAssignment.end) {
      return false;
    }

    const studentAssignmentEnd = parseISO(studentAssignment.end);
    const daysTilDue = differenceInCalendarDays(
      studentAssignmentEnd,
      new Date()
    );

    if (assignmentStatus === Student_Assignment_Status_Enum.Complete) {
      return assignmentStatus === studentAssignment.status;
    } else if (assignmentStatus === Student_Assignment_Status_Enum.Incomplete) {
      return daysTilDue === 0 && assignmentStatus === studentAssignment.status;
    } else if (assignmentStatus === CalculatedStudentAssignmentStatus.Overdue) {
      return daysTilDue < 0;
    } else if (
      assignmentStatus === CalculatedStudentAssignmentStatus.DueToday
    ) {
      return daysTilDue === 0;
    } else if (
      assignmentStatus === CalculatedStudentAssignmentStatus.InProgress
    ) {
      return daysTilDue > 0;
    } else if (
      assignmentStatus === CalculatedStudentAssignmentStatus.NeedsGrading
    ) {
      return (
        studentAssignment.status === Student_Assignment_Status_Enum.Complete &&
        studentAssignment.pointsEarned === null &&
        studentAssignment.assignment.graded
      );
    } else {
      return true;
    }
  };

export const getFilteredHomeStudentAssignments = createSelector(
  getHomeStudentAssignments,
  getFilters,
  (
    studentAssignments: HomeStudentAssignmentFragment[] | null,
    filters: Filters
  ) => {
    const filteredHomeStudentAssignments =
      studentAssignments
        ?.filter(isHomeStudentAssignmentAssignedToStudent(filters.studentId))
        .filter(isHomeStudentAssignmentForCourse(filters.courseId))
        .filter(doesHomeStudentAssignmentHaveType(filters.assignmentType))
        .filter(
          doesHomeStudentAssignmentHaveStatus(filters.studentAssignmentStatus)
        ) ?? null;

    return filteredHomeStudentAssignments;
  }
);

export const getFilteredHomeStudentAssignmentsLength = createSelector(
  getFilteredHomeStudentAssignments,
  (filteredStudentAssignments: HomeStudentAssignmentFragment[] | null) =>
    filteredStudentAssignments?.length ?? null
);

export const getCurrentStudentIdFilter = createSelector(
  getFilters,
  (filters: Filters) => filters.studentId
);

export const getCurrentStudentAssignmentStatusFilter = createSelector(
  getFilters,
  (filters: Filters) => filters.studentAssignmentStatus
);

export const getCurrentAssignmentTypeFilter = createSelector(
  getFilters,
  (filters: Filters) => filters.assignmentType
);

export const getCurrentCourseIdFilter = createSelector(
  getFilters,
  (filters: Filters) => filters.courseId
);
