import {
  addDays,
  eachDayOfInterval,
  format,
  formatISO,
  getTime,
  isWithinInterval,
  nextDay,
  parseISO,
  previousDay,
} from 'date-fns';
import { Day } from 'date-fns/types';

import { dateFnsWeekDayOrder, weekDayOrder } from 'constants/weekDayOrder';

import {
  Assignments,
  Student_Assignment_Status_Enum,
  Student_Assignments,
  Student_Assignments_Insert_Input,
  Student_Courses,
  Students,
} from 'graphql/types/generated';

/**
 * Course days are days on which the course schedules assignments (typically Monday - Friday)
 * Vacation days are days have a vacation scheduled
 * Available days are Course days that are not Vacation days
 */
export enum DayStatus {
  Course,
  Available,
}

type StudentCourse = Pick<
  Student_Courses,
  'id' | 'start' | 'daysOfTheWeek' | 'courseDayOffsets'
> & {
  student: Pick<Students, 'id'>;
};

export type StudentAssignmentForUpdatingGradingStyle = Pick<
  Student_Assignments,
  'pointsEarned' | 'pointsAvailable'
>;

type StudentAssignment = Pick<Student_Assignments, 'id' | 'start' | 'end'>;

type StudentAssignmentForReschedule = Pick<
  Student_Assignments,
  'id' | 'start' | 'end' | 'freeForm' | 'courseDayOffset' | 'status'
>;

type StudentAssignmentForRescheduleUsingAssignment =
  StudentAssignmentForReschedule & {
    assignment: Pick<Assignments, 'week' | 'day' | 'duration'>;
  };

type NewStudentAssignmentForAssignment = Pick<
  Student_Assignments_Insert_Input,
  'studentId' | 'studentCourseId' | 'end' | 'start' | 'status'
>;

type NewStudentAssignmentForStudentCourse = Pick<
  Student_Assignments_Insert_Input,
  'studentId' | 'assignmentId' | 'end' | 'start' | 'status'
>;

export interface DateRange {
  from: Date;
  to: Date;
}

interface StudentAssignmentDates {
  start: Date | null;
  end: Date | null;
}

/**
 * Returns the date for the nth day of the week after a date.
 * For example: The 2nd Wednesday after 05/25/2022
 *
 * @param startDay {Date} date to start jumping from
 * @param endDayOfWeekIndex {Day} date-fns Day index of the day of the week we're jumping to
 * @param weeksToJump {number} number of weeks to jump (the Nth in 5th Monday)
 * @param jumpingForward {boolean} true if we're jumping forward, false if we're jumping backwards in time
 * @return {Date} date object without time
 */
export function jumpWeeks(
  startDay: Date,
  endDayOfWeekIndex: Day,
  weeksToJump: number,
  jumpingForward: boolean
): Date {
  let currentDay = startDay;
  for (let i = 0; i < weeksToJump; i++) {
    currentDay = jumpingForward
      ? nextDay(currentDay, endDayOfWeekIndex)
      : previousDay(currentDay, endDayOfWeekIndex);
  }

  return currentDay;
}

/**
 * Returns date with the time component removed
 *
 * @param date {Date} date object to remove the time from
 * @return {Date} date object without time
 */
export function removeTimeFromDate(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/**
 * Returns true if day is within a range in dateRanges (inclusive), otherwise false
 *
 * @param day {Date} the day to test inclusion
 * @param dateRanges {DateRange[]} list of date ranges in which to test for inclusion
 * @return {boolean} true if day is within a range in dateRanges (inclusive), otherwise false
 */
export function dayInRanges(day: Date, dateRanges: DateRange[]): boolean {
  const dayNoTime = removeTimeFromDate(day);
  return dateRanges.some((dateRange) =>
    isWithinInterval(dayNoTime, {
      start: removeTimeFromDate(dateRange.from),
      end: removeTimeFromDate(dateRange.to),
    })
  );
}

/**
 * Returns an Array of days of the week on which student assignments may be assigned
 *
 * @param studentCourseDays {Record<string, boolean>} days of week on which student assignments may be assigned
 * @return {Array<string>} an Array of days of the week on which student assignments may be assigned
 */
export function getValidDaysOfWeek(
  studentCourseDays: Record<string, boolean>
): Array<string> {
  return weekDayOrder.filter((dayOfWeek) => studentCourseDays[dayOfWeek]);
}

/**
 * Counts the number of days per week a course is based on the student course days data structure
 *
 * @param studentCourseDays {Record<string, boolean>} days of week on which student assignments may be assigned
 * @return {number} number of days per week for the course
 */
export function getNumCourseDaysPerWeekFromStudentCourseDays(
  studentCourseDays: Record<string, boolean>
): number {
  return Object.values(studentCourseDays).reduce(
    (acc, day) => (day ? acc + 1 : acc),
    0
  );
}

/**
 * Get day number in course based on the week day combo and number of days per week
 * (e.g. Week 2, Day 1 in a 5 days a week course is "day 6")
 * also see getWeekAndDayInCourse()
 *
 * @param week {number | null} week number in course
 * @param day {number | null} day number in course
 * @param numCourseDaysPerWeek {number} number of days per week in the course
 * @return {number} day number in course for the given week day combo
 */
export function getNumDayInCourse(
  numCourseDaysPerWeek: number,
  week?: number | null,
  day?: number | null
): number {
  return week && day ? day + (week - 1) * numCourseDaysPerWeek : 0;
}

/**
 * Return the specific week and day of numDayInCourse
 * Also see getNumDayInCourse()
 *
 * @param numCourseDaysPerWeek number of days per week in the course
 * @param numDayInCourse number of days into the course - 1 is the first day of course
 * @returns the week and day of a day number in a course
 */
export function getWeekAndDayInCourse(
  numCourseDaysPerWeek: number,
  numDayInCourse: number
): { week: number; day: number } {
  const week = Math.floor((numDayInCourse - 1) / numCourseDaysPerWeek) + 1;
  const day = numDayInCourse - (week - 1) * numCourseDaysPerWeek;

  return { week, day };
}

/**
 * Returns a sum of all the courseDayOffsets for a Student Course after a week and day
 *
 * @param week {number} course week to sum up to
 * @param day {number} course day to sum up to
 * @param studentCourseDayOffsets {Record<number, number>} student course courseDayOffsets to sum up
 * @return {number} sum of all the offsets
 */
export function calcTotalStudentCourseDayOffset(
  week: number,
  day: number,
  numCourseDaysPerWeek: number,
  studentCourseDayOffsets: Record<number, number>
): number {
  return Array.from(
    { length: getNumDayInCourse(numCourseDaysPerWeek, week, day) },
    (_, i) => i + 1
  ).reduce((acc, day) => {
    return (studentCourseDayOffsets[day] ?? 0) + acc;
  }, 0);
}
/**
 * Returns the number of "course", and "available" days within dateRange (inclusive)
 *
 * If DateRange is "reversed" ('to' < 'from') the returned numbers will be <= 0.
 * If DateRange is one day ('to' == 'from') the returned numbers will be <= 1
 *
 * @param inDateRange {DateRange} range of dates in which to count day types, inclusive
 * @param studentCourseDays {Record<string, boolean>} days of week on which student assignments may be assigned
 * @param vacations {DateRange[]} list of vacations
 * @return {{ [DayStatus.Available]: number; [DayStatus.Vacation]: number; [DayStatus.Course]: number }}
 *         number of "course" and "available" days within dateRange
 */
export function getDayStatusCounts(
  dateRange: DateRange,
  studentCourseDays: Record<string, boolean>,
  vacations: DateRange[] = []
): {
  [DayStatus.Available]: number;
  [DayStatus.Course]: number;
} {
  const dateRangeIsReversed = dateRange.from > dateRange.to ? true : false;
  dateRange = dateRangeIsReversed
    ? {
        from: removeTimeFromDate(dateRange.to),
        to: removeTimeFromDate(dateRange.from),
      }
    : {
        from: removeTimeFromDate(dateRange.from),
        to: removeTimeFromDate(dateRange.to),
      };

  let dayCounts = {
    [DayStatus.Available]: 0,
    [DayStatus.Course]: 0,
  };

  let currentDay = dateRange.from;
  while (currentDay <= dateRange.to) {
    const isCourseDay = studentCourseDays[format(currentDay, 'EEEE')];
    const isVacationDay = dayInRanges(currentDay, vacations);

    if (isCourseDay) {
      dayCounts[DayStatus.Course] += 1;
    }
    if (isCourseDay && !isVacationDay) {
      dayCounts[DayStatus.Available] += 1;
    }

    currentDay = addDays(currentDay, 1);
  }

  if (dateRangeIsReversed) {
    return {
      [DayStatus.Available]: -dayCounts[DayStatus.Available],
      [DayStatus.Course]: -dayCounts[DayStatus.Course],
    };
  } else {
    return dayCounts;
  }
}

/**
 * Returns the number of course days that a student assignment is offset from its originally scheduled date
 *
 * Can be negative if the student assignment start date occurs before its originally scheduled date
 *
 * @param studentCourseStart {Date} date that the student course starts
 * @param studentCourseDays {Record<string, boolean>} days of the week on which student assignments may be assigned
 * @param studentVacations {DateRange[]} list of vacations for the student in the student assignment, which do not count as course days
 * @param studentCourseDayOffsets {Record<number, number>} courseDayOffsets for the student course
 * @param assignmentDay {number | null} day number in the course week that the assignment is scheduled (1-indexed)
 * @param assignmentWeek {number | null} course week number that the assignment is scheduled (1-indexed)
 * @param studentAssignmentStart {Date} date that the student assignment is scheduled
 * @return {number} number of course days that a student assignment is offset from its originally scheduled date
 */
export function calcCourseDayOffset(
  studentCourseStart: Date,
  studentCourseDays: Record<string, boolean>,
  studentVacations: DateRange[],
  studentCourseDayOffsets: Record<number, number>,
  assignmentDay: number | null,
  assignmentWeek: number | null,
  studentAssignmentStart: Date
): number {
  const studentCourseDayOffset =
    assignmentWeek && assignmentDay
      ? calcTotalStudentCourseDayOffset(
          assignmentWeek,
          assignmentDay,
          getNumCourseDaysPerWeekFromStudentCourseDays(studentCourseDays),
          studentCourseDayOffsets
        )
      : 0;

  const originalStudentAssignmentStart = getStudentAssignmentDates(
    studentCourseStart,
    studentCourseDays,
    studentVacations,
    assignmentDay,
    assignmentWeek,
    0,
    studentCourseDayOffset
  ).start;

  if (!originalStudentAssignmentStart) {
    return 0;
  }

  const numCourseDaysInRange = getDayStatusCounts(
    {
      from: originalStudentAssignmentStart,
      to: studentAssignmentStart,
    },
    studentCourseDays,
    studentVacations
  )[DayStatus.Available];

  const courseDayOffset =
    numCourseDaysInRange < 0
      ? numCourseDaysInRange + 1
      : numCourseDaysInRange - 1;

  return courseDayOffset;
}

/**
 * For each assignment day number in the student course, calculate its calendar date,
 * and return a mapping of each valid calendar date to its day numbers in the student course
 *
 * It’s possible that the same calendar day maps to 1+ assignment day numbers.
 * For example, with an offset map like { 3:-1, 4:-2, 5:-3}.
 *
 * @param studentCourseStart date that the student course starts
 * @param studentCourseDays days of the week on which student assignments may be assigned
 * @param numberOfWeeks number of weeks in the course
 * @param numberOfDaysPerWeek number of days in each week of the course
 * @param studentVacations list of student vacations
 * @param studentCourseDayOffsets courseDayOffsets for the student course
 * @returns map of each calendar date (as an ISO string) to its day numbers in the student course
 */
export function calcWeekDayMap(
  studentCourseStart: Date,
  studentCourseDays: Record<string, boolean>,
  numberOfWeeks: number,
  numberOfDaysPerWeek: number,
  studentVacations: DateRange[],
  studentCourseDayOffsets: Record<number, number>
): Record<string, number[]> {
  const weekNums = Array.from({ length: numberOfWeeks }, (_, i) => i + 1);
  const dayNums = Array.from({ length: numberOfDaysPerWeek }, (_, i) => i + 1);

  const dateToDayMap = weekNums
    .flatMap((week) =>
      dayNums.map((day) => {
        return {
          week,
          day,
          numDayInCourse: getNumDayInCourse(numberOfDaysPerWeek, week, day),
        };
      })
    )
    .reduce((map, { week, day, numDayInCourse }) => {
      const dayInCourse = getStudentAssignmentDates(
        studentCourseStart,
        studentCourseDays,
        studentVacations,
        day,
        week,
        0,
        calcTotalStudentCourseDayOffset(
          week,
          day,
          numberOfDaysPerWeek,
          studentCourseDayOffsets
        )
      ).start;

      if (dayInCourse) {
        const calendarDate = formatISO(dayInCourse, { representation: 'date' });
        if (calendarDate in map) {
          map[calendarDate].push(numDayInCourse);
        } else {
          map[calendarDate] = [numDayInCourse];
        }
      }
      return map;
    }, {} as Record<string, number[]>);

  return dateToDayMap;
}

/**
 * Returns the nth day of specified status on or after startDay
 *
 * If n > 0  return the nth day of the specified status on or after startDay
 * If n is < 0, return the nth day of the specified status on or before startDay
 * If n === 0, return startDay
 * If countStartDay === false, don't count startDay is a valid day
 * Examples:
 * The next "course day" on or after dayX (may return dayX): use n === 1
 * The next "course day" after dayX: use n === 1, countStartDay === false
 * The previous "course day" on or before dayX (may return dayX): use n === 1
 * The the 3rd "vacation day" on or after dayX: use n === 3
 * Two "available days" on or before dayX: use n === -2
 *
 * @param nDaysAhead {number} indicates the nth day to look for - use n === 1 to find the next day of specified type
 * @param dayStatus {DayStatus} type of day to count - "available" days are non-vacation course days
 * @param startDay {Date} first day on which to start counting towards the nth day
 * @param studentCourseDays {Record<string, boolean>} days of week on which student assignments may be assigned
 * @param vacationDays {Date[]} flat list of vacation days
 * @param countStartDay {boolean} if true (default), startDay contributes to the count towards N days
 * @return {Date} nth day of specified status on or after startDay
 */
export function findNthDay(
  nDaysAhead: number,
  dayStatus: DayStatus,
  startDay: Date,
  studentCourseDays: Record<string, boolean>,
  vacationDays: Date[] = [],
  countStartDay: boolean = true
): Date {
  if (nDaysAhead === 0) {
    return startDay;
  }

  const lookingForward = nDaysAhead > 0;

  let daysToJump =
    countStartDay && lookingForward ? nDaysAhead - 1 : nDaysAhead;

  const courseDaysOfWeek = getValidDaysOfWeek(studentCourseDays);
  const nCourseDays = courseDaysOfWeek.length;

  // If startDay is not a course day we need to find the next closest course day
  // after startDay
  let startCourseDayIndex = null;
  while (startCourseDayIndex === null) {
    const startDayIndex = courseDaysOfWeek.indexOf(format(startDay, 'EEEE'));
    if (startDayIndex === -1) {
      startDay = addDays(startDay, 1);
    } else {
      startCourseDayIndex = startDayIndex;
    }
  }

  // We need a list of unique days that are unavailable (vacations),
  // so we need to filter out overlaps
  let unavailableDays =
    dayStatus === DayStatus.Available
      ? vacationDays
          .filter((day) => courseDaysOfWeek.indexOf(format(day, 'EEEE')) !== -1)
          .map(removeTimeFromDate)
          .map(getTime)
          .filter((day, i, days) => days.indexOf(day) === i)
          .map((day) => new Date(day))
      : [];

  let endDay: Date | null = null;

  while (endDay === null || daysToJump !== 0) {
    const endCourseDayIndex = lookingForward
      ? (startCourseDayIndex + daysToJump) % nCourseDays
      : (nCourseDays +
          ((startCourseDayIndex - Math.abs(daysToJump)) % nCourseDays)) %
        nCourseDays;

    const endDayOfWeekIndex = Math.max(
      0,
      dateFnsWeekDayOrder.indexOf(courseDaysOfWeek[endCourseDayIndex])
    ) as Day;

    const weeksToJump = Math.ceil(Math.abs(daysToJump) / nCourseDays);

    endDay = jumpWeeks(
      startDay,
      endDayOfWeekIndex,
      weeksToJump,
      lookingForward
    );

    const currentInterval = lookingForward
      ? { start: startDay, end: endDay }
      : { start: endDay, end: startDay };
    const unavailableDaysInInterval = unavailableDays.filter((day) =>
      isWithinInterval(day, currentInterval)
    );

    // Remove all used unavailable days so that we don't double count them
    // on the next iteration
    unavailableDays = unavailableDays.filter(
      (day) => !unavailableDaysInInterval.includes(day)
    );

    daysToJump = unavailableDaysInInterval.length;
    startDay = endDay;
    startCourseDayIndex = courseDaysOfWeek.indexOf(format(startDay, 'EEEE'));
  }

  return endDay;
}

/**
 * Return assignment start and end dates given course and assignment schedule.
 *
 * @param studentCourseStartDate {Date} date that the course should start
 * @param studentCourseDays {Record<string, boolean>} days of week on which student assignments may be assigned
 * @param studentVacations {DateRange[]} list of vacations on which assignments should not be scheduled
 * @param assignmentDay {number | null} course day number that the assignment is scheduled (1-indexed)
 * @param assignmentWeek {number | null} course week number that the assignment is scheduled (1-indexed)
 * @param assignmentDuration {number} number of calendar days until the assignment is due. '0' means the same day
 * @param studentAssignmentCourseDayOffset {number} number of course days before or after the parent assignment's day/week
 * @param studentCourseCourseDayOffset {number} number of course days to adjust the parent assignment's day/week
 * @return {StudentAssignmentDates} an object containing a start date and end date for the new Student Assignment
 */
export function getStudentAssignmentDates(
  studentCourseStartDate: Date,
  studentCourseDays: Record<string, boolean>,
  studentVacations: DateRange[],
  assignmentDay: number | null,
  assignmentWeek: number | null,
  assignmentDuration: number,
  studentAssignmentCourseDayOffset: number = 0,
  studentCourseCourseDayOffset: number = 0
): StudentAssignmentDates {
  if (!assignmentDay || !assignmentWeek) {
    return { start: null, end: null };
  }

  const vacationDays = studentVacations.flatMap((vacation) =>
    eachDayOfInterval({ start: vacation.from, end: vacation.to })
  );

  const numCourseDaysPerWeek = getValidDaysOfWeek(studentCourseDays).length;
  const assignmentCourseDayNum = getNumDayInCourse(
    numCourseDaysPerWeek,
    assignmentWeek,
    assignmentDay
  );

  const startDayNoOffset = findNthDay(
    assignmentCourseDayNum,
    DayStatus.Available,
    studentCourseStartDate,
    studentCourseDays,
    vacationDays,
    true
  );

  const combinedOffset =
    studentAssignmentCourseDayOffset + studentCourseCourseDayOffset;
  const startDay = combinedOffset
    ? findNthDay(
        combinedOffset,
        DayStatus.Available,
        startDayNoOffset,
        studentCourseDays,
        vacationDays,
        /*countStartDay === */ false
      )
    : startDayNoOffset;

  const endDay = addDays(startDay, assignmentDuration);

  return { start: startDay, end: endDay };
}

/**
 * Return a new student assignment given an assignment.
 *
 * @param assignmentId {string} course assignment id
 * @param assignmentDay {number | null} course assignment day
 * @param assignmentWeek {number | null} course assignment week
 * @param assignmentDuration {number} course assignment duration
 * @param studentId {string} student ID to which the assignment will be assigned
 * @param studentCourseStartDate {Date} date that the course should start. Always a Monday
 * @param studentCourseDays {Record<string, boolean} days of week on which student assignments may be assigned
 * @param studentVacations {DateRange[]} vacations for student in student course
 * @return {NewStudentAssignmentForStudentCourse} a student assignment
 */
function createStudentAssignmentForStudentCourse(
  assignmentId: string,
  assignmentDay: number | null,
  assignmentWeek: number | null,
  assignmentDuration: number,
  studentId: string,
  studentCourseStartDate: Date,
  studentCourseDays: Record<string, boolean>,
  studentVacations: DateRange[]
): NewStudentAssignmentForStudentCourse {
  const studentAssignmentDates = getStudentAssignmentDates(
    studentCourseStartDate,
    studentCourseDays,
    studentVacations,
    assignmentDay,
    assignmentWeek,
    assignmentDuration
  );

  return {
    studentId,
    assignmentId: assignmentId,
    end: studentAssignmentDates.end
      ? formatISO(studentAssignmentDates.end, { representation: 'date' })
      : null,
    start: studentAssignmentDates.start
      ? formatISO(studentAssignmentDates.start, {
          representation: 'date',
        })
      : null,
    status: Student_Assignment_Status_Enum.Incomplete,
  };
}

/**
 * Return a list of student assignments given a list of assignments. Only creates student assignments
 * when the assignment has a day and week.
 *
 * @param assignments {Pick<Assignments, 'id' | 'day' | 'week' | 'duration'>[]} course assignments
 * @param studentId {string} student ID to which the assignment will be assigned
 * @param studentCourseStartDate {Date} course start date
 * @param studentCourseDays {Record<string, boolean} days of week on which student assignments may be assigned
 * @param studentVacations {DateRange[]} list of vacations around which to avoid scheduling student assignments
 * @return {NewStudentAssignmentForStudentCourse[] | null} an array of student assignments on success or null upon failure
 */
export function createStudentAssignmentsForStudentCourse(
  assignments: Pick<Assignments, 'id' | 'day' | 'week' | 'duration'>[],
  studentId: string,
  studentCourseStartDate: Date,
  studentCourseDays: Record<string, boolean>,
  studentVacations: DateRange[] = []
): NewStudentAssignmentForStudentCourse[] {
  if (!assignments.length) {
    return [];
  }

  return assignments.reduce((assignments, assignment) => {
    assignments.push(
      createStudentAssignmentForStudentCourse(
        assignment.id,
        assignment?.day ?? null,
        assignment?.week ?? null,
        assignment.duration,
        studentId,
        studentCourseStartDate,
        studentCourseDays,
        studentVacations
      )
    );

    return assignments;
  }, [] as NewStudentAssignmentForStudentCourse[]);
}

/**
 * Creates a student assignment for an assignment and the provided student course
 *
 * @param studentCourse {StudentCourse} student course for which to create a student assignment
 * @param studentVacations {DateRange[]} vacations for the student in studentCourse
 * @param assignmentDay {number | null} the day the source assignment is scheduled for if null
 * the student assignment start and end will be null
 * @param assignmentWeek {number | null} the week the source assignment is scheduled for if null
 * the student assignment start and end will be null
 * @param assignmentDuration {number} the duration of the source assignment
 * @return {NewStudentAssignmentForAssignment | null} a student assignment on success or null upon failure
 */
function createStudentAssignmentForAssignment(
  studentCourse: StudentCourse,
  studentVacations: DateRange[],
  assignmentDay: number | null,
  assignmentWeek: number | null,
  assignmentDuration: number
): NewStudentAssignmentForAssignment {
  const baseStudentAssignment = {
    studentId: studentCourse.student.id,
    studentCourseId: studentCourse.id,
    status: Student_Assignment_Status_Enum.Incomplete,
  };

  const courseDayOffset =
    assignmentWeek && assignmentDay
      ? calcTotalStudentCourseDayOffset(
          assignmentWeek,
          assignmentDay,
          getNumCourseDaysPerWeekFromStudentCourseDays(
            studentCourse.daysOfTheWeek as Record<string, boolean>
          ),
          (studentCourse.courseDayOffsets ?? {}) as Record<number, number>
        )
      : 0;

  const studentCourseStartDate = parseISO(studentCourse.start);
  const studentAssignmentDates = getStudentAssignmentDates(
    studentCourseStartDate,
    studentCourse.daysOfTheWeek as Record<string, boolean>,
    studentVacations,
    assignmentDay,
    assignmentWeek,
    assignmentDuration,
    courseDayOffset
  );

  return {
    end: studentAssignmentDates.end
      ? formatISO(studentAssignmentDates.end, { representation: 'date' })
      : null,
    start: studentAssignmentDates.start
      ? formatISO(studentAssignmentDates.start, {
          representation: 'date',
        })
      : null,
    ...baseStudentAssignment,
  };
}

/**
 * Creates student assignments for an assignment and the provided student courses
 *
 * This is used when a new assignment gets created and needs to be turned into a student
 * assignment for each student enrolled in the course
 *
 * @param studentCourses {StudentCourse[]} student courses for which to create student assignments - indexes aligned with studentVacations
 * @param studentVacations {DateRange[]} vacations for the students in studentCourses indexes aligned with studentCourses
 * @param assignmentDay {number | null} the day the source assignment is scheduled for
 * @param assignmentWeek {number | null} the week the source assignment is scheduled for
 * @param assignmentDuration {number} the duration of the source assignment
 * @return {NewStudentAssignmentForAssignment[] | null} an array of student assignments on success or null upon failure
 */
export function createStudentAssignmentsForAssignment(
  studentCourses: StudentCourse[],
  studentVacations: DateRange[][],
  assignmentDay: number | null,
  assignmentWeek: number | null,
  assignmentDuration: number
): NewStudentAssignmentForAssignment[] | null {
  if (studentCourses.length !== studentVacations.length) {
    return null;
  }

  let studentAssignments = [];
  for (let i = 0; i < studentCourses.length; i++) {
    const studentAssignment = createStudentAssignmentForAssignment(
      studentCourses[i],
      studentVacations[i],
      assignmentDay,
      assignmentWeek,
      assignmentDuration
    );

    studentAssignments.push(studentAssignment);
  }

  return studentAssignments;
}

/**
 * Updates the end date of an existing student assignment based on a duration
 *
 * @param studentAssignments {StudentAssignment[]} student assignments with start and end date
 * @param assignmentDuration {number} the duration of the source assignment
 * @return {StudentAssignment[]} an array of student assignments with updated end date
 */
export function updateStudentAssignmentsEnd(
  studentAssignments: StudentAssignment[],
  assignmentDuration: number
): StudentAssignment[] {
  return studentAssignments.map((studentAssignment) => {
    return {
      ...studentAssignment,
      end: studentAssignment.start
        ? formatISO(
            addDays(parseISO(studentAssignment.start), assignmentDuration),
            { representation: 'date' }
          )
        : null,
    };
  });
}

/**
 * Places a student assignment on the correct dates based on vacations and its week/day/duration
 *
 * Note, this function will not reschedule student assignments that are free-form or completed.
 *
 * @param studentCourseStartDate {Date} start day of the student course that the assignment is being reschedule for
 * @param studentAssignments {StudentAssignmentForReschedule} student assignment to reschedule
 * @param studentCourseDays {Record<string, boolean>} days of week on which student assignments may be assigned
 * @param vacations {DateRange[]} list of existing vacations for the relevant student
 * @param day {number | null} new day to use for rescheduling student assignment
 * @param week {number | null} new week to use for rescheduling student assignment
 * @param duration {number} new duration to use for rescheduling student assignment
 * @param studentCourseDayOffsets {Record<number,number>} course day offsets for student course
 * @return {StudentAssignmentDates[]} list of rescheduled student assignments
 */
export function rescheduleStudentAssignment(
  studentCourseStartDate: Date,
  studentAssignment: StudentAssignmentForReschedule,
  studentCourseDays: Record<string, boolean>,
  vacations: DateRange[],
  day: number | null,
  week: number | null,
  duration: number,
  studentCourseDayOffsets: Record<number, number>
): StudentAssignmentForReschedule {
  if (
    studentAssignment.freeForm ||
    studentAssignment.status === Student_Assignment_Status_Enum.Complete
  ) {
    return studentAssignment;
  }

  const studentCourseDayOffset =
    week && day
      ? calcTotalStudentCourseDayOffset(
          week,
          day,
          getNumCourseDaysPerWeekFromStudentCourseDays(studentCourseDays),
          studentCourseDayOffsets
        )
      : 0;

  const newStudentAssignmentDates = getStudentAssignmentDates(
    studentCourseStartDate,
    studentCourseDays,
    vacations,
    day,
    week,
    duration,
    studentAssignment.courseDayOffset || 0,
    studentCourseDayOffset
  );

  return {
    ...studentAssignment,
    start: newStudentAssignmentDates.start
      ? formatISO(newStudentAssignmentDates.start, {
          representation: 'date',
        })
      : null,
    end: newStudentAssignmentDates.end
      ? formatISO(newStudentAssignmentDates.end, {
          representation: 'date',
        })
      : null,
  };
}

/**
 * Return the provided student assignment with an updated courseDayOffset, or the original student assignment if unsuccessful
 *
 * The updated courseDayOffset is calculated such that the student assignment's start/end dates would remain the same after a reschedule.
 * I.e., this function "fixes" the student assignment's offset to match its current location in the course calendar.
 *
 * Note, this function will not reoffset student assignments that are free-form or completed.
 *
 * @param studentCourseStartDate start day of the student course that the assignment belongs to
 * @param studentAssignment student assignment to reoffset
 * @param studentCourseDays boolean>} days of week on which student assignments may be assigned
 * @param vacations list of existing vacations for the relevant student
 * @param studentCourseDayOffsets {Record<number,number>} course day offsets for student course
 * @return student assignment with updated offset, or the original student assignment if unsuccessful
 */
export function reoffsetStudentAssignment(
  studentCourseStartDate: Date,
  studentAssignment: StudentAssignmentForRescheduleUsingAssignment,
  studentCourseDays: Record<string, boolean>,
  vacations: DateRange[],
  studentCourseDayOffsets: Record<number, number>
): StudentAssignmentForReschedule {
  const currentStartDate = studentAssignment.start;
  if (
    !currentStartDate ||
    !studentAssignment.assignment.day ||
    !studentAssignment.assignment.week
  ) {
    // TODO: log?
    return studentAssignment;
  }

  const scheduledCourseDay = studentAssignment.assignment.day;
  const scheduledCourseWeek = studentAssignment.assignment.week;

  const stateDateIfRescheduled = rescheduleStudentAssignment(
    studentCourseStartDate,
    studentAssignment,
    studentCourseDays,
    vacations,
    scheduledCourseDay,
    scheduledCourseWeek,
    0,
    studentCourseDayOffsets
  ).start;

  if (!stateDateIfRescheduled) {
    // TODO: log?
    return studentAssignment;
  }

  const numCourseDaysOff = getDayStatusCounts(
    { from: parseISO(currentStartDate), to: parseISO(stateDateIfRescheduled) },
    studentCourseDays,
    vacations
  )[DayStatus.Available];

  const courseDayOffsetAdjustment =
    numCourseDaysOff < 0 ? numCourseDaysOff + 1 : numCourseDaysOff - 1;

  return {
    ...studentAssignment,
    courseDayOffset:
      (studentAssignment.courseDayOffset ?? 0) - courseDayOffsetAdjustment,
  };
}

/**
 * Return a list of student assignments with updated courseDayOffsets
 *
 * @param studentCourseStartDate start day of the student course
 * @param studentAssignments student assignments to reoffset
 * @param studentCourseDays days of the week on which student assignments may be assigned
 * @param vacations list of existing vacations for the student
 * @param studentCourseDayOffsets course day offsets for the student course
 * @returns
 */
export function reoffsetStudentAssignments(
  studentCourseStartDate: Date,
  studentAssignments: StudentAssignmentForRescheduleUsingAssignment[],
  studentCourseDays: Record<string, boolean>,
  vacations: DateRange[],
  studentCourseDayOffsets: Record<number, number>
): StudentAssignmentForReschedule[] {
  const offsetAdjustedStudentAssignments = studentAssignments.map(
    (studentAssignment) =>
      reoffsetStudentAssignment(
        studentCourseStartDate,
        studentAssignment,
        studentCourseDays,
        vacations,
        studentCourseDayOffsets
      )
  );

  return offsetAdjustedStudentAssignments;
}

/**
 * Places each student assignment on the correct dates based on vacations and its week/day/duration
 *
 * Note, this function will not reschedule student assignments that are free-form.
 *
 * @param studentCourseStartDate {Date} start day of the student course that assignments are being reschedule for
 * @param studentAssignments {StudentAssignmentForRescheduleUsingAssignment[]} student assignments to reschedule
 * @param studentCourseDays {Record<string, boolean>} days of week on which student assignments may be assigned
 * @param vacations {DateRange[]} list of existing vacations
 * @param studentCourseDayOffsets {Record<number,number>} course day offsets for student course
 * @return {StudentAssignmentDates[]} list of rescheduled student assignments
 */
export function rescheduleStudentAssignments(
  studentCourseStartDate: Date,
  studentAssignments: StudentAssignmentForRescheduleUsingAssignment[],
  studentCourseDays: Record<string, boolean>,
  vacations: DateRange[],
  studentCourseDayOffsets: Record<number, number>
): StudentAssignmentForReschedule[] {
  const rescheduledStudentAssignments = studentAssignments.map(
    (studentAssignment) =>
      rescheduleStudentAssignment(
        studentCourseStartDate,
        studentAssignment,
        studentCourseDays,
        vacations,
        studentAssignment.assignment.day ?? null,
        studentAssignment.assignment.week ?? null,
        studentAssignment.assignment.duration,
        studentCourseDayOffsets
      )
  );

  return rescheduledStudentAssignments;
}

export function convertToPercentGradingStyle(
  studentAssignments: StudentAssignmentForUpdatingGradingStyle[]
): StudentAssignmentForUpdatingGradingStyle[] {
  return studentAssignments.map((studentAssignment) => {
    const originalPointsEarned = studentAssignment.pointsEarned;
    const originalPointsAvailable = studentAssignment.pointsAvailable;

    let newPointsEarned;

    if (originalPointsEarned === undefined || originalPointsEarned === null) {
      newPointsEarned = null;
    } else if (
      originalPointsAvailable === undefined ||
      originalPointsAvailable === null
    ) {
      newPointsEarned = originalPointsEarned; // Not sure how this would happen
    } else if (originalPointsEarned === 0 && originalPointsAvailable === 0) {
      newPointsEarned = null; // A 0/0 assignment
    } else if (originalPointsEarned > 0 && originalPointsAvailable === 0) {
      newPointsEarned = 100; // An Extra Credit assignment
    } else if (originalPointsEarned === 0) {
      newPointsEarned = 0; // A 0/X assignment
    } else {
      newPointsEarned = Number(
        (100 * (originalPointsEarned / originalPointsAvailable)).toFixed(1)
      );
    }

    return {
      ...studentAssignment,
      pointsEarned: newPointsEarned,
      pointsAvailable: 100,
    };
  });
}
