import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import { CSSProperties, MouseEventHandler, forwardRef } from 'react';
import { Link } from 'react-router-dom';

import CircularLoader from 'components/CircularLoader';

import styles from './Button.module.scss';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Minimal = 'minimal',
  Link = 'link',
}

export enum ButtonSpacing {
  Normal = 'normal',
  Compressed = 'compressed',
}

export enum ButtonColor {
  Normal = 'normal',
  Positive = 'positive',
  Negative = 'negative',
}

interface ButtonProps {
  id?: string;
  className?: string;
  style?: CSSProperties;
  children: JSX.Element[] | JSX.Element;
  onClick?: MouseEventHandler;
  to?: string;
  color?: ButtonColor;
  submit?: boolean;
  disabled?: boolean;
  type?: ButtonType;
  spacing?: ButtonSpacing;
  loading?: boolean;
  tippyContent?: string | JSX.Element | JSX.Element[] | null;
}

const Button = forwardRef(
  (
    {
      id,
      className,
      style,
      children,
      onClick,
      to,
      submit,
      tippyContent,
      disabled = false,
      type = ButtonType.Primary,
      spacing = ButtonSpacing.Normal,
      color = ButtonColor.Normal,
      loading = false,
    }: ButtonProps,
    ref: React.Ref<any>
  ): JSX.Element => {
    const spacingStyles = {
      [styles.NormalSpacing]:
        type !== ButtonType.Link && spacing === ButtonSpacing.Normal,
      [styles.CompressedSpacing]:
        type !== ButtonType.Link && spacing === ButtonSpacing.Compressed,
    };

    const typeStyles = {
      [styles.PrimaryType]: type === ButtonType.Primary,
      [styles.SecondaryType]: type === ButtonType.Secondary,
      [styles.MinimalType]: type === ButtonType.Minimal,
      [styles.LinkType]: type === ButtonType.Link,
    };

    const colorStyles = {
      [styles.NormalColor]: color === ButtonColor.Normal && !disabled,
      [styles.PositiveColor]: color === ButtonColor.Positive && !disabled,
      [styles.NegativeColor]: color === ButtonColor.Negative && !disabled,
      [styles.Disabled]: disabled,
    };

    const buttonClasses: string = classnames(
      'syl-button',
      styles.Button,
      className,
      spacingStyles,
      typeStyles,
      colorStyles
    );

    const classes: string = tippyContent
      ? buttonClasses
      : classnames(buttonClasses, className);

    const button =
      to && !disabled ? (
        <Link ref={ref} className={className} to={to}>
          <div className={classes} onClick={onClick}>
            {loading ? <CircularLoader className={styles.Loader} /> : children}
          </div>
        </Link>
      ) : (
        <button
          id={id}
          ref={ref}
          className={classes}
          type={!!submit ? 'submit' : 'button'}
          onClick={onClick}
          disabled={disabled}
          style={style}
        >
          {loading ? <CircularLoader className={styles.Loader} /> : children}
        </button>
      );

    return tippyContent ? (
      <Tippy content={tippyContent}>
        <span className={className}>{button}</span>
      </Tippy>
    ) : (
      button
    );
  }
);

export default Button;
