import { gql } from '@apollo/client';

import { CommentInfo } from './comments';
import { CustomAssignmentType } from './customAssignmentType';
import { StudentsEvents } from './student';

export const HomeStudentAssignment = gql`
  fragment HomeStudentAssignment on student_assignments {
    id
    start
    end
    freeForm
    status
    pointsEarned
    assignment {
      id
      name
      customAssignmentType {
        ...CustomAssignmentType
      }
      graded
      course {
        id
        name
        color
      }
    }
    student {
      id
      name
      picture
    }
    studentCourse {
      id
    }
  }
  ${CustomAssignmentType}
`;

export const CalendarStudentAssignments = gql`
  fragment CalendarStudentAssignments on student_assignments {
    id
    start
    end
    freeForm
    status
    pointsEarned
    student {
      id
      name
      picture
    }
    assignment {
      id
      name
      description
      customAssignmentType {
        ...CustomAssignmentType
      }
      week
      day
      graded
    }
  }
  ${CustomAssignmentType}
`;

export const PlanningStudentAssignment = gql`
  fragment PlanningStudentAssignment on student_assignments {
    id
    start
    end
    freeForm
    courseDayOffset
    student {
      id
      name
      picture
      ...StudentsEvents
    }
    studentCourse {
      id
      start
      daysOfTheWeek
      courseDayOffsets
    }
  }
  ${StudentsEvents}
`;

export const GradebookStudentAssignment = gql`
  fragment GradebookStudentAssignment on student_assignments {
    id
    assignment {
      id
      name
      assignmentType
      customAssignmentType {
        ...CustomAssignmentType
      }
      graded
    }
    status
    pointsEarned
    pointsAvailable
    start
    completed
  }
  ${CustomAssignmentType}
`;

export const NewStudentAssignment = gql`
  fragment NewStudentAssignment on student_assignments {
    ...HomeStudentAssignment
    ...CalendarStudentAssignments
    ...PlanningStudentAssignment
    ...GradebookStudentAssignment
    ...StudentAssignmentToReschedule
  }
  ${HomeStudentAssignment}
  ${CalendarStudentAssignments}
  ${PlanningStudentAssignment}
  ${GradebookStudentAssignment}
`;

export const StudentAssignmentDetails = gql`
  fragment StudentAssignmentDetails on student_assignments {
    id
    start
    end
    pointsEarned
    pointsAvailable
    freeForm
    status
    courseDayOffset
    assignment {
      id
      name
      week
      day
      description
      teachersNote @skip(if: $isStudent)
      customAssignmentType {
        ...CustomAssignmentType
      }
      assignmentType
      graded
      created
      attachments {
        id
        url
      }
      course {
        id
        name
        color
      }
    }
    student {
      id
      name
      picture
      ...StudentsEvents
    }
    studentCourse {
      id
      daysOfTheWeek
      dailySortOrder
      courseDayOffsets
      start
      course {
        id
        gradingStyle
      }
    }
    submissions {
      id
      url
    }
    comments {
      ...CommentInfo
    }
  }
  ${StudentsEvents}
  ${CommentInfo}
  ${CustomAssignmentType}
`;

export const StudentAssignmentToReschedule = gql`
  fragment StudentAssignmentToReschedule on student_assignments {
    id
    studentId
    assignmentId
    start
    end
    freeForm
    status
    studentCourseId
    courseDayOffset
    assignment {
      id
      week
      day
      duration
    }
  }
`;

export const StudentAssignmentToUpdateGradingStyle = gql`
  fragment StudentAssignmentToUpdateGradingStyle on student_assignments {
    id
    studentId
    assignmentId
    status
    studentCourseId
    pointsEarned
    pointsAvailable
  }
`;
